<template>
  <v-card
    flat
    class="d-flex my-6 mx-9 rounded-lg justify-space-around align-center"
    color="#f5f7f7"
    height="200"
  >
    <div class="pa-2">visualizer placeholder...</div>
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>